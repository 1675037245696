<template>
    <div class="container bg_fff H_100vh">
        <div class="flex_row_between P_3">
            <van-icon name="arrow-left" @click="$router.go(-1)" size=".35rem"/>
        </div>
        <div class="PLR_4">
            <div class="flex_rowL">
                <span class="fontS_18 color_333">{{ msgDetail.message_type | msgType}}</span>
                <div class="MT_1 ML_3">
                    
                    <van-icon name="clock-o" size=".25rem" />
                    <span style="margin-left:0.05rem">{{msgDetail.date1}}</span>
                    <!-- <span :class="msgDetail.is_confirm == 1? 'wd' : 'yd'">{{msgDetail.is_confirm ==1 ? "未读" : "已读"}}</span> -->
                </div>
            </div>
            <div class="PTB_2 color_555 flex_rowL">
                <van-icon name="bag-o" size=".35rem" />
                <span class="PL_2">{{msgDetail.company_name}}</span>
            </div>
            <div  class="lineH_4 textL fontS_17 color_333">
                
                <!-- <br> <br> <br>  -->
                <!-- 入职通知 -->
                <div v-if="msgDetail.message_type == 2">
                    <div v-html="content"></div>
                    如您在24小时内未做任何操作，我们将默认您已拒绝入职该公司，如您有其他异议请联系萝卜猎手客服，客服将向您做完整的解答。
                    <div v-if="status == '6' "  class="aa W_100 PB_6 flex_row_around" >
                    <div class="W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff" @click="goaddinfo(1)">
                        同意并完善信息 
                    </div>
                    <div class="W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff" @click="goaddinfo(2)">
                        拒绝入职
                    </div>
                </div>
                <div v-else   class="cc W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff">
                    <div v-if="status == 1 || status == 4 || status == 6">未入职</div>
                   <div v-else>{{status | workStatus}}</div>
                </div>
          
                </div> 

                <!-- 离职通知 -->
                <div v-if="msgDetail.message_type == 3">
                    <div v-html="content"></div>
                    如您在24小时内未做任何操作，我们将默认您已授权同意该离职通知，如您有其他异议请联系萝卜猎手客服，客服将向您做完整的解答。
                    <div v-if="status == '5'" class="aa flex33" >
                        <div class="W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff" @click="lz(1)" >
                            同意离职
                        </div>
                        <div class="W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff" @click="lz(2)">
                            拒绝离职
                        </div>
                    </div>

                    <div v-else  class="cc W_45 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff">
                       <div v-if="status == 2 || status == 5 || status == 7">已入职</div>
                       <div v-else>{{status | workStatus}}</div>
                    </div>
                </div> 
                
                <!-- 技能认可 -->
                 <div  v-if="msgDetail.message_type == 4">
                    <div v-html="content"></div>
                    它将会在您之后的职业生涯持续为您提供价值和贡献。提示:越多的技能越容易获得点亮和认可。
                     
                        <div class="s1 W_90 PTB_2 B_radius_5 fontS_16 textC bg_FF4800_FC9E46 color_fff " @click="confirm">
                            创建综合技能
                        </div>
                       

                </div>

                <!-- 简介转介绍授权 -->
                <div v-if="msgDetail.message_type == 5">
                    <div v-html="content"></div>
                    背调如您在24小时内未做任何操作，我们将默认您已授权同意该授权通知，如您有其他异议请联系萝卜猎手客服，客服将向您做完整的解答。
                </div> 

                <div v-if="msgDetail.message_type == 6"></div>

                <!-- 简历下载 -->
                <div v-if="msgDetail.message_type == 7">
                    <div v-html="content"></div>
                </div> 
            </div>
        </div>

        
       
    </div>
</template>

<script>
    import '@/assets/css/common.css';
import { Toast,Dialog } from 'vant';
    // import { Toast } from 'vant'

    export default {
        name: "messageDetail",
        data() {
            return {
                title: '搜索',
                settingShow:false,
                affirmShow:false,
                msgDetail:{},
                content:"",
                status:"" ,// 判断是否入离职
               
            }
        },
        created() {
            let msgDetail = JSON.parse(this.$route.query.msgDetail)
            
            console.log(msgDetail)
            let content = msgDetail.content
            content = this.ReplaceUrl(content)
            this.msgDetail = msgDetail
            this.content = content
            if(msgDetail.message_type == 7) return 
            this.$http.post("/user/v1/personal_info/jobStatus",{reqType:"jobStatus",companyId:this.msgDetail.e_id})
            .then((res)=>{
                res = JSON.parse(res.data)
                console.log(res)
                if(res.code == 0){
                    this.status = res.data.jobStatus
                }else{
                    Toast.fail(res.msg)
                }
               
                console.log(res.data.jobStatus)
                 console.log(this.status)
            })
            .catch((e)=>{
                console.log(e)
            })
           
        },
        methods: {
            lz(type){
                console.log(type)
                if(type == 1){
                     Dialog.confirm({
                        title: '确认离职?',
                        message: '',
                    })
                    .then(() => {
                        // on confirm
                          let  { e_id, id, message_type } = this.msgDetail
                        this.$http.post("/user/v1/personal_info/entryExit",{reqType:"entryExit",status:message_type,type:type,companyId:e_id,messageId:id})
                        .then((res)=>{
                            res = JSON.parse(res.data)
                            if(res.code == 0){
                                Toast.success("保存成功")
                                setTimeout(()=>{
                                    this.$router.push("./message")
                                },1000)
                            }else{
                                Toast.fail(res.msg)
                            }
                            console.log(res)
                        })
                        .catch((e)=>{
                            console.log(e)
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
                }else{
                     let  { e_id, id, message_type } = this.msgDetail
                        this.$http.post("/user/v1/personal_info/entryExit",{reqType:"entryExit",status:message_type,type:type,companyId:e_id,messageId:id})
                        .then((res)=>{
                            res = JSON.parse(res.data)
                            if(res.code == 0){
                                Toast.success("保存成功")
                                setTimeout(()=>{
                                   this.$router.push("./message")
                                },1000)
                            }else{
                                Toast.fail(res.msg)
                            }
                            console.log(res)
                        })
                        .catch((e)=>{
                            console.log(e)
                        })
                }
               
              
            },
          goaddinfo(type){
              if(type == 1){
                 let { e_id, id, message_type } = this.msgDetail
                this.$http.post("/user/v1/personal_info/entryExit",{reqType:"entryExit",status:message_type,type:type,companyId:e_id,messageId:id})
                .then((res)=>{
                    console.log(res)
                    res = JSON.parse(res.data)
                    if(res.code == 0){
                        sessionStorage.setItem("messageType","messageType")
                        sessionStorage.setItem("e_id",e_id)
                        this.$router.push({path:"./addInfo"})
                    }else{
                        Toast.fail(res.msg)
                    }
                })
                .catch((e)=>{
                    console.log(e)
                })
              }else{
                  let { e_id, id, message_type } = this.msgDetail
                this.$http.post("/user/v1/personal_info/entryExit",{reqType:"entryExit",status:message_type,type:type,companyId:e_id,messageId:id})
                .then((res)=>{
                    console.log(res)
                    res = JSON.parse(res.data)
                    if(res.code == 0){
                        sessionStorage.setItem("messageType","messageType")
                        sessionStorage.setItem("e_id",e_id)
                        setTimeout(()=>{
                            Toast.success("保存成功")
                            this.$router.go(-1)
                        },1000) 
                        
                    }else{
                        Toast.fail(res.msg)
                    }
                })
                .catch((e)=>{
                    console.log(e)
                })
              }
              
              console.log(this.msgDetail)
            
          },
          ReplaceUrl(text){
            var re =/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
            var s = text.replace(re,function(a){
            return '<a style="color:blue" href="'+a+'" target=_blank>'+a+'</a>';
            });
            return s;
          },

        confirm(){
            this.$router.push("./choiceTag")
        },
        goBack(){
            this.affirmShow = false
            setTimeout(()=>{
                this.$router.go(-1)
            },1000) 
        }
        },
        components: {}
    }
</script>

<style scoped>
.flex33{
    display:flex;
    justify-content: space-between;
}
.aa{
    margin-top:4rem;
}
.s1{
    position: fixed;
    bottom:0.6rem;
    left:0.34rem;
}
.cc{
    position:absolute;
    bottom:1rem;
    left:2rem;
}
.yd{
    width: 0.8rem;
height: 0.38rem;
background: #006AFF;
border-radius: 0.15rem;
color:#fff;
padding:0.08rem 0.3rem;
margin-left:0.3rem;
}
.wd{
    width: 0.8rem;
height: 0.38rem;
background: #979797;
border-radius: 0.15rem;
color:#fff;
padding:0.08rem 0.3rem;
margin-left:0.3rem;
}
.container{
    position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
</style>